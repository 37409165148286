































































import Vue from 'vue'

import Loader from '@/components/ui/Loader/Loader.vue'
import WeatherStation from '@/components/data/weatherstation/WeatherStation.vue'
import ToggleSwitch from '@/components/ui/ToggleSwitch/ToggleSwitch.vue'

import { convertDate, stringifyDate } from '@/helpers/dates'

import { vrsStore } from '@/store'
import { fetchWeatherStationDataFilteredByDate } from '@/store/weatherstation/store'

export default Vue.extend({
  components: {
    'ui-loader': Loader,
    'ui-toggle-switch': ToggleSwitch,
    weatherstation: WeatherStation
  },
  props: {
    eventId: {
      type: String
    },
    stationCode: {
      type: String
    }
  },
  data: () => ({
    app: vrsStore.modules.app.state,
    event: vrsStore.modules.event.state,
    weatherStation: vrsStore.state.weatherStation,
    length: vrsStore.state.app.data.queryString.length || 240,
    displaySession: true,
    availableLengths: [{
      value: undefined,
      label: 'custom'
    }, {
      value: 60,
      label: '1 hour'
    }, {
      value: 120,
      label: '2 hours'
    }, {
      value: 180,
      label: '3 hours'
    }, {
      value: 240,
      label: '4 hours'
    }, {
      value: 300,
      label: '5 hours'
    }, {
      value: 360,
      label: '6 hours'
    }]
  }),
  computed: {
    selectedRangeLocal () {
      if (!vrsStore.state.event.data) return null
      const toDatestring = vrsStore.state.app.data.live === true ? vrsStore.state.app.data.maximumTimeLive : vrsStore.state.app.data.referenceDate
      if (!toDatestring) return null
      const from = convertDate(toDatestring, vrsStore.state.event.data.timezone)
        .minus({ minutes: this.$route.query.length || this.length })
      return [
        stringifyDate(from),
        toDatestring
      ]
    }
  },
  methods: {
    toggleDisplaySession (value): void {
      this.displaySession = value
    },
    updateZoom (newZoom) {
      if (!newZoom) return
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          zoomTimeline: newZoom
        }
      })
    },
    fetchData () {
      if (!vrsStore.state.event.data) return
      if (!this.selectedRangeLocal) return
      fetchWeatherStationDataFilteredByDate(
        vrsStore.state.event.data.stations.map(s => s.id.toString()),
        convertDate(this.selectedRangeLocal[0], vrsStore.state.event.data.timezone),
        convertDate(this.selectedRangeLocal[1], vrsStore.state.event.data.timezone)
      )
    },
    getStationData (index) {
      if (vrsStore.state.weatherStation.data && index < vrsStore.state.weatherStation.data.length) {
        return vrsStore.state.weatherStation.data[index]
      } else {
        return null
      }
    }
  },
  watch: {
    selectedRangeLocal: {
      immediate: true,
      handler (newRange, oldRange) {
        if (!newRange) return
        if (newRange[0] === oldRange?.[0] && newRange[1] === oldRange?.[1]) return
        this.fetchData()
      }
    },
    '$route.query.length' (newValue, oldValue) {
      if (newValue === oldValue) return
      const indexAvailableLength = this.availableLengths.findIndex(e => e.value === parseInt(newValue))
      if (indexAvailableLength === -1) {
        this.length = undefined
      } else {
        this.length = this.availableLengths[indexAvailableLength].value
      }
    },
    length (newValue) {
      if (newValue !== undefined) {
        this.$router.push({
          path: this.$route.path,
          params: this.$route.params,
          query: {
            ...this.$route.query,
            length: newValue
          }
        })
      }
    }
  }
})
