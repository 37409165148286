var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Observations"},[(_vm.event.loading)?_c('ui-loader',{attrs:{"message":"Loading event data..."}}):_vm._e(),_c('section',[(_vm.event.data)?_c('main',{staticClass:"relative flex flex-col"},[(_vm.event.data.stations.length === 0)?_c('div',{staticClass:"no-data"},[_vm._v(" No station available for this event. ")]):_c('div',{staticClass:"select-period lg:ml-auto lg:absolute lg:p-0 flex justify-around items-center mt-2"},[_c('div',{staticClass:"flex items-center mr-4"},[_c('span',{staticClass:"pr-2"},[_vm._v("Highlight session times")]),_c('ui-toggle-switch',{attrs:{"checked":_vm.displaySession},on:{"click":function($event){return _vm.toggleDisplaySession($event)}}})],1),_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"pr-2"},[_vm._v("Period's length")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.length),expression:"length"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.length=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.availableLengths),function(item){return _c('option',{key:item.label,domProps:{"value":item.value}},[_vm._v(_vm._s(item.label))])}),0)])]),_vm._l((_vm.event.data.stations),function(station,index){return _c('div',{key:index,staticClass:"border-b border-gray border-solid mb-4 pb-4"},[(_vm.$route.params.stationCode === undefined || _vm.$route.params.stationCode === station.properties.reference)?_c('weatherstation',{attrs:{"station-data":_vm.getStationData(index),"station":station,"displaySession":_vm.displaySession,"display-detail":_vm.$route.params.stationCode === station.properties.reference,"loading":_vm.weatherStation.loading,"detail-url":{
            name: 'observationsDetails',
            params: { eventId: station.properties.event, stationCode: station.properties.reference },
            query: _vm.$route.query
          },"dashboard-url":{
            name: 'observations',
            params: { eventId: station.properties.event },
            query: _vm.$route.query
          },"sessions":_vm.event.data.sessions,"selectedRangeLocal":_vm.selectedRangeLocal,"event-geojson":_vm.event.data.geojson}}):_vm._e()],1)})],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }